import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textareaWrapper', 'wordCounter'];
  static values = { invalidMessage: String, maxWordCount: Number };

  connect() {
    const textarea = this.textareaWrapperTarget.querySelector('textarea');
    this.wordCounterTarget.innerHTML =
      '0/' + this.maxWordCountValue.toString() + ' words';

    textarea.addEventListener('input', (event) => {
      this.checkInputWordCount(textarea);
    });
  }

  checkInputWordCount(textarea) {
    const wordCount = textarea.value.trim().split(' ').length;

    this.wordCounterTarget.innerHTML =
      wordCount.toString() + '/' + this.maxWordCountValue + ' words';

    if (wordCount > this.maxWordCountValue) {
      this.wordCounterTarget.classList.add('danger');

      // resetting focus with invalid message ensures the mdc invalid color will appear
      textarea.setCustomValidity(this.invalidMessageValue);
      textarea.blur();
      textarea.focus();
    } else {
      this.wordCounterTarget.classList.remove('danger');

      // resetting focus with empty validity message ensures the mdc input focus color will appear
      textarea.setCustomValidity('');
      textarea.blur();
      textarea.focus();
    }
  }
}
